<template>
    <div>
        <div v-if="authUserPermission['end-user-search-material-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--7">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col text-center">
                                <h2 class="mb-0">
                                    <b>
                                        {{ tt('advance_search_material') }}
                                    </b>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4">
                                <!-- MATERIAL NUMBER -->
                                <!-- <el-select 
                                class="form-control-sm col-md-12 mb-4" 
                                v-model="data.search.material_number" 
                                :placeholder="tt('material_number_example')" 
                                :remote-method="querySearchMaterialNumber" 
                                :loading="loading" 
                                filterable remote reserve-keyword clearable 
                                @keyup.enter.native="searchMaterial">
                                    <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                                </el-select> -->
                                <el-input
                                type="text"
                                class="form-control-sm col-md-12 mb-4"
                                v-model="data.search.material_number"
                                :placeholder="tt('material_number_example')"
                                @keyup.enter.native="searchMaterial"
                                ></el-input>

                                <!-- MCR NUMBER -->
                                <el-input
                                type="text"
                                class="form-control-sm col-md-12 mb-4"
                                v-model="data.search.mcr_number"
                                :placeholder="tt('mcr_number_example')"
                                @keyup.enter.native="searchMaterial"
                                ></el-input>

                                <!-- ITEM NAME -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.item_name" :placeholder="tt('item_name_example')" :remote-method="querySearchItemName" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="si in standardItemName" :key="si.inc" :label="si.inc +' - '+ si.item_name" :value="si.inc"></el-option>
                                </el-select>

                                <!-- NATO GROUP CLASS -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.group_class" :placeholder="tt('nato_group_class_example')" :remote-method="querySearchGroupClass" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="gc in groupClass" :key="gc.nsc" :label="gc.nsc +' - '+ gc.description" :value="gc.nsc"></el-option>
                                </el-select>

                                <!-- MATERIAL TYPE -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.material_type" :placeholder="tt('material_type_example')" :remote-method="querySearchMaterialType" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="mt in materialType" :key="mt.code" :label="mt.code +' - '+ mt.description" :value="mt.code"></el-option>
                                </el-select>

                                <!-- MATERIAL GROUP -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.material_group" :placeholder="tt('material_group_example')" :remote-method="querySearchMaterialGroup" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="mg in materialGroup" :key="mg.code" :label="mg.code +' - '+ mg.description" :value="mg.code"></el-option>
                                </el-select>

                                <!-- MANUFACTURE -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.manufacture" :placeholder="tt('manufacture_code_example')" :remote-method="querySearchManufacture" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="mf in manufacture" :key="mf.manufacture_code" :label="mf.manufacture_code +' - '+ mf.manufacture_name" :value="mf.manufacture_code"></el-option>
                                </el-select>

                                <!-- MANUFACTURE REFERENCE -->
                                <el-input
                                type="text"
                                class="form-control-sm col-md-12 mb-4"
                                v-model="data.search.manufacture_ref"
                                :placeholder="tt('manufacture_reference_example')"
                                clearable
                                @keyup.enter.native="searchMaterial"
                                ></el-input>
                            </div>
                            <div class="col-md-4">
                                <!-- COMPANY -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.company" :placeholder="tt('company_example')" :remote-method="querySearchCompany" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="cp in companyCode" :key="cp.company_code" :label="cp.company_code +' - '+ cp.company_description" :value="cp.company_code"></el-option>
                                </el-select>

                                <!-- PLANT -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.plant" :placeholder="tt('plant_example')" :remote-method="querySearchPlant" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="pl in plant" :key="pl.plant_code" :label="pl.plant_code +' - '+ pl.description" :value="pl.plant_code"></el-option>
                                </el-select>

                                <!-- LOCATION -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.location" :placeholder="tt('location_example')" :remote-method="querySearchLocation" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="lc in location" :key="lc.location_code" :label="lc.location_code +' - '+ lc.location_description" :value="lc.location_code"></el-option>
                                </el-select>

                                <!-- EQUIPMENT -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.equipment_code" :placeholder="tt('equipment_example')" :remote-method="querySearchEquipment" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="ep in equipmentCode" :key="ep.equipment_code" :label="ep.equipment_code +' - '+ ep.equipment_name" :value="ep.equipment_code"></el-option>
                                </el-select>

                                <!-- SCOPE OF SUPPLY -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.scope_of_supply" :placeholder="tt('scope_of_supply_example')" :remote-method="querySearchScopeOfSupply" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="sos in scopeOfSupply" :key="sos.sos_header" :label="sos.sos_header +' - '+ sos.sos_description" :value="sos.sos_header"></el-option>
                                </el-select>

                                <!-- UOM -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.uom" :placeholder="tt('uom_example')" :remote-method="querySearchUnitOfMeasure" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="uom in unitOfMeasure" :key="uom.code" :label="uom.code +' - '+ uom.description" :value="uom.code"></el-option>
                                </el-select>

                                <!-- FABRICATION NON FABRICATION -->
                                <el-select
                                    class="form-control-sm col-md-12 mb-4 "
                                    v-model="data.search.fab_non_fab"
                                    :placeholder="tt('fab_non_fab')"
                                    :loading="loading"
                                    :automatic-dropdown="false"
                                    clearable
                                    @keyup.enter.native="searchMaterial"
                                >
                                    <el-option 
                                        v-for="fnf in fabNonFab"
                                        :key="fnf"
                                        :value="fnf"
                                        :label="fnf"
                                    >
                                    </el-option>
                                </el-select>

                                <!-- BOM NON BOM -->
                                <el-select
                                    class="form-control-sm col-md-12 mb-4 "
                                    v-model="data.search.bom_non_bom"
                                    :placeholder="tt('bom_non_bom')"
                                    :loading="loading"
                                    :automatic-dropdown="false"
                                    clearable
                                    @keyup.enter.native="searchMaterial"
                                >
                                    <el-option 
                                        v-for="bnb in bomNonBom"
                                        :key="bnb"
                                        :value="bnb"
                                        :label="bnb"
                                    >
                                    </el-option>
                                </el-select>


                            </div>
                            <div class="col-md-4">
                                <!-- CRITICAL NON CRITICAL -->
                                <el-select
                                    class="form-control-sm col-md-12 mb-4 "
                                    v-model="data.search.critical_non_critical"
                                    :placeholder="tt('critical_non_critical')"
                                    :loading="loading"
                                    :automatic-dropdown="false"
                                    clearable
                                    @keyup.enter.native="searchMaterial"
                                >
                                    <el-option 
                                        v-for="cnc in criticalNonCritical"
                                        :key="cnc"
                                        :value="cnc"
                                        :label="cnc"
                                    >
                                    </el-option>
                                </el-select>

                                <!-- CATALOG STATUS -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.cat_status" :placeholder="tt('catalog_status_example')" :remote-method="querySearchCatalogStatus" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="cs in catalogStatus" :key="cs.code" :label="cs.code +' - '+ cs.description" :value="cs.code"></el-option>
                                </el-select>

                                <!-- CATALOG TYPE -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.item_type_code" :placeholder="tt('item_type_example')" :loading="loading" reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="it in itemType" :key="it" :label="it" :value="it"></el-option>
                                </el-select>

                                <!-- UPDATED BY -->
                                <el-select class="form-control-sm col-md-12 mb-4 " v-model="data.search.updated_by" :placeholder="tt('updated_by_example')" :remote-method="querySearchUpdatedBy" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                    <el-option v-for="ub in updatedBy" :key="ub.namecode" :label="ub.namecode +' - '+ ub.name" :value="ub.namecode"></el-option>
                                </el-select>

                                <!-- SHORT DESCRIPTION -->
                                <el-input
                                type="text"
                                class="form-control-sm col-md-12 mb-4"
                                v-model="data.search.short_description"
                                :placeholder="tt('short_description_example')"
                                clearable
                                @keyup.enter.native="searchMaterial"
                                ></el-input>

                                <!-- KEYWORD -->
                                <el-input
                                type="text"
                                class="form-control-sm col-md-12 mb-4"
                                v-model="data.search.keyword"
                                :placeholder="tt('keyword_example')"
                                @keyup.enter.native="searchMaterial"
                                ></el-input>

                                <!-- UPDATED AT -->
                                <div class="demo-input-suffix">
                                    <el-input
                                    class="form-control-sm col-md-12 mb-4"
                                    v-model="data.search.date"
                                    type="date"
                                    :placeholder="tt('updated_at')"
                                    @keyup.enter.native="searchMaterial"
                                    ></el-input>
                                </div>

                                <el-select
                                    class="form-control-sm col-md-12 mb-4 "
                                    v-model="data.search.shp"
                                    :placeholder="tt('shp')"
                                    @keyup.enter.native="searchMaterial"
                                    clearable
                                >
                                    <el-option value="SHP" label="SHP"></el-option>
                                    <el-option value="Non SHP" label="Non SHP"></el-option>
                                </el-select>


                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <!-- LONG DESCRIPTION -->
                                <el-input
                                type="text"
                                class="form-control-sm col-md-12 mb-4"
                                v-model="data.search.long_description"
                                :placeholder="tt('long_description_example')"
                                @keyup.enter.native="searchMaterial"
                                ></el-input>
                            </div>
                        </div>
                        <base-button type="sm" @click="searchMaterial" class="btn btn-block btn-lg btn-primary pt-3 pb-3" size="lg">
                            {{ tt('search') }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import mcrNumber from '@/services/material/draftList.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import groupClass from '@/services/dictionary/natoGroupClass.service';
    import materialType from '@/services/master/materialType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import manufacture from '@/services/dictionary/manufacture.service';
    import companyCode from '@/services/master/companyCode.service';
    import plant from '@/services/master/plantCode.service';
    import location from '@/services/master/locationCode.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import scopeOfSupply from '@/services/dictionary/scopeOfSupply.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import catalogStatus from '@/services/master/catalogStatus.service';
    import updatedBy from '@/services/manage/manageAccount.service';
    import materialNumber from '@/services/master/materialNumber.service';

    export default {
        data() {
            return {
                data: {
                    search: {
                        material_number: '',
                        mcr_number: '',
                        item_name: '',
                        group_class: '',
                        material_type: '',
                        material_group: '',
                        manufacture: '',
                        manufacture_ref: '',
                        company: '',
                        plant: '',
                        location: '',
                        equipment_code: '',
                        scope_of_supply: '',
                        uom: '',
                        fab_non_fab: '',
                        bom_non_bom: '',
                        critical_non_critical: '',
                        cat_status: '',
                        item_type_code: '',
                        updated_by: '',
                        short_description:'',
                        keyword: '',
                        date: '',
                        long_description: '',
                        shp:'',
                        test_type:'',
                        type: 'search-material',
                    },
                },
                endUserSearchMaterial: [],
                materialNumber: [],
                standardItemName: [],
                groupClass: [],
                materialType: [],
                materialGroup: [],
                manufacture: [],
                manufactureRef: [],
                companyCode: [],
                plant: [],
                location: [],
                equipmentCode: [],
                scopeOfSupply: [],
                unitOfMeasure: [],
                fabNonFab: [],
                bomNonBom: [],
                criticalNonCritical: [],
                catalogStatus: [],
                catalogType: [],
                updatedBy: [],
                links:[],
                listSelect:[],
                loading:false,
                selectSearch: {
                    loadTimeout: null
                },
                itemType:[]
            }
        },
        mounted() {
            this.$nextTick(function () {
                this.getParameterMcr();
            })
        
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        methods: {
            searchMaterial() {
                this.$router.push({ path: 'end-user-search-material', query: this.data.search });
            },
            querySearchItemName(query) {
                if (query.length > 3) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, standardItemName.get(null, {per_page: 'none',item_name: query})).onSuccess(function(response) {
                            context.standardItemName = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.standardItemName = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchGroupClass(query) {
                if (query.length > 2) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, groupClass.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.groupClass = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.groupClass = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchMaterialType(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, materialType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.materialType = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.materialType = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchMaterialGroup(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, materialGroup.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.materialGroup = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.materialGroup = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchManufacture(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, manufacture.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.manufacture = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.manufacture = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchCompany(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, companyCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.companyCode = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.companyCode = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchPlant(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, plant.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.plant = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.plant = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchLocation(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, location.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.location = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.location = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchEquipment(query) {
                if (query.length > 3) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, equipmentCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.equipmentCode = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.equipmentCode = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchScopeOfSupply(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, scopeOfSupply.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.scopeOfSupply = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.scopeOfSupply = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchUnitOfMeasure(query) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, unitOfMeasure.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.unitOfMeasure = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.unitOfMeasure = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
            },
            querySearchCatalogStatus(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, catalogStatus.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.catalogStatus = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.catalogStatus = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchUpdatedBy(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, updatedBy.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.updatedBy = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.updatedBy = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchMaterialNumber(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.loading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, materialNumber.get({search: query})).onSuccess(function(response) {  
		                    context.materialNumber = response.data.data;
		                    context.loading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.materialNumber = [];
		                        context.loading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            getParameterMcr() {
                let context = this;
                Api(context, mcrNumber.getParameterMcr())
                .onSuccess(function(response) {
                  context.itemType            = response.data.data.item_type
                  context.fabNonFab           = response.data.data.fab_non_fab;
                  context.bomNonBom           = response.data.data.bom_non_bom;
                  context.criticalNonCritical = response.data.data.critical_non_critical;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.itemType            = [];
                    context.fabNonFab           = [];
                    context.bomNonBom           = [];
                    context.criticalNonCritical = [];
                  }
                }).call();
            },
            selectMaterialNumber(item) {
                this.data.search.material_number = item.value
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
        }
    };
</script>
<style>
    .width {
        width: 95%; 
    }
</style>
